import React from 'react';
import IconBase from './IconBase';

const Star = (props) => (
  <IconBase viewBox="0 -960 960 960" {...props}>
    <path d="m354-287 126-76 126 77-33-144 111-96-146-13-58-136-58 135-146 13 111 97-33 143ZM233-120l65-281L80-590l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Zm247-350Z"/>
  </IconBase>
);

export default Star;

