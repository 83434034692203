import React, { useState } from 'react';




const Conditions= () => {




  return (
    <>
      hello conditions

    </>
  );
};

export default Conditions;