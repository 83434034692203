import React, {useCallback, useState, useEffect} from 'react'
import {connect} from 'react-redux'
import {createStructuredSelector} from 'reselect'
import {includes, toLower, get} from 'lodash'
import {Modal, Button, Searchable, Input, Box, Text} from 'components'
import {
  GroupList,
  getNestedGroupsArray,
  addMembersToGroups,
  create,
} from 'groups'
import NewGroup from './NewGroup'
import useCustomTranslation from 'lib/customT'
import TransWrapper from 'lib/transWrapper'

const JoinGroupModal = ({
  bulk: members,
  hide,
  resetBulk,
  addMembersToGroups,
  groups,
  createGroup,
}) => {
  const t = useCustomTranslation()
  const [bulk, setBulk] = useState([])
  const [initialBulk, setInitialBulk] = useState([])

  function arraysIdentical(arr1, arr2) {
    if (arr1.length !== arr2.length) {
        return false; // Arrays have different lengths
    }

    // Sort both arrays and compare
    let sortedArr1 = arr1.slice().sort(); // Use slice() to avoid modifying the original array
    let sortedArr2 = arr2.slice().sort();

    for (let i = 0; i < sortedArr1.length; i++) {
        if (sortedArr1[i] !== sortedArr2[i]) {
            return false; // If any sorted element is different, arrays are not identical
        }
    }

    return true; // All elements match
  }

  useEffect(() => {
    
    const userGroups = []
    groups.forEach(group => {
      group.users.forEach((user)=> {
        if(members.includes(user)){
          userGroups.push(`${group.id}`)
        }
      })
      setBulk(userGroups)
      setInitialBulk(userGroups)
    });

  }, [members, groups]);

  const toggle = useCallback(
    ({target: {checked, value}}) => {

      if(!initialBulk.includes(value)){ 
        setBulk(!checked ? bulk.filter((id) => id !== value) : [...bulk, value])
      }
    },
    [setBulk, bulk]
  )

  const handleSubmit = useCallback(
    (newGroup) => {
      new Promise((resolve) => {
        if (get(newGroup, 'name')) {
          createGroup({values: newGroup, resolve})
        } else {
          resolve(bulk)
        }
      })
        .then((response) => {
          const groups = Array.isArray(response)
            ? response
            : [response.userGroupId]

          try {
            new Promise((resolve) => {
              addMembersToGroups({members, groups, resolve})
            })
          } catch{
            console.log("error")
          }
        })
        .then(() => {
          hide()
          resetBulk()
        })
    },
    [bulk, members, createGroup, addMembersToGroups, hide, resetBulk]
  )

  const predicate = useCallback(
    (item, value) => includes(toLower(item.title), toLower(value)),
    []
  )

  return (
    <Modal hide={hide} title={t('Tilføj til gruppe')}>
      <Box p={3}>
        {groups.length > 0 ? (
          <Searchable items={groups} predicate={predicate}>
            {({items, handleChange}) => (
              <>
                <Box mb={1}>
                  <Input
                    onChange={handleChange}
                    small
                    placeholder={`${t('Søg i grupper')}...`}
                  />
                </Box>
                { items.length > 0 ? <GroupList groups={items} bulk={bulk} toggleGroup={toggle} /> : <Text center>{t("Søgningen matcher ikke et gruppenavn")}</Text>}
                <Box mt={3}>
                  <Button small primary type="button" disabled={!bulk.length > 0 || arraysIdentical(bulk, initialBulk)} onClick={
                    (e)=> {
                      e.preventDefault()
                      handleSubmit()
                    }
                  } block >
                    {t('Tilføj til gruppe')}
                  </Button>
                </Box>
              </>
            )}
          </Searchable>
        ) : (
          <NewGroup onSubmit={handleSubmit} />
        )}
      </Box>
    </Modal>
  )
}

const enhancer = connect(
  createStructuredSelector({
    groups: getNestedGroupsArray,
  }),
  {
    addMembersToGroups: addMembersToGroups.requested,
    createGroup: create.requested,
  }
)

export default enhancer(JoinGroupModal)
